import { css } from 'twin.macro';

import { Fragment, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, Form, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import EditElement from './EditElement';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ID from '../../../_helpers/uuid';

import TeamSelectFilter from '../TeamSelectFilter';
import ChanelSelectFilter from '../ChannelSelectFilter';

//icons
import { MdDelete } from 'react-icons/md';

import { fetchWrapper } from '../../../_helpers';

import FormElement from './FormElement';
import { AiFillCopy } from 'react-icons/ai';
import { customCheckbox } from '../../../_elements/CustomCheckbox';

const DynamicPreview = ({
    placeholderName = null,
    formName = null,
    selectedRooms = null,
    selectedTeams = null,
    editformId = null,
    formformType = 'normal',
    specialityForm,
    formLocationRequired = true,
    formElements,
    teamId = null,
    channelId = null,
    teams,
    formVisibleTo,
    currUser,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [selectedTeamOptions, setSelectedTeamOptions] = useState(selectedTeams);
    const [selectedRoomOptions, setSelectedRoomOptions] = useState(selectedRooms);
    const [name, setName] = useState('');
    const [formType, setFormType] = useState(formformType);
    const [specialityFormType, setSpecialityFormType] = useState(specialityForm?.type);
    const [isLocationRequired, setIsLocationRequired] = useState(formLocationRequired);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [visibleTo, setVisibleTo] = useState(formVisibleTo || ['supervisor', 'doctor', 'family', 'user']);

    function handleVisibleToChange(event) {
        const role = event.target.value;
        setVisibleTo((prev) => {
            const newRoles = prev.includes(role) ? prev.filter((x) => x !== role) : [...visibleTo, role];
            if (
                ['user', 'family', 'doctor'].some((basicRole) => newRoles.includes(basicRole)) &&
                !newRoles.includes('supervisor')
            ) {
                newRoles.push('supervisor');
            }
            return newRoles;
        });
    }

    const options = [
        { value: 'normal', label: 'Normal' },
        { value: 'sos', label: 'Start Of Service' },
        { value: 'eos', label: 'End Of Service' },
        { value: 'multipleSubmission', label: 'Multiple Submissions' },
        { value: 'speciality', label: 'Speciality Form' },
    ];

    const specialityFormOptions = [
        { value: 'timeOff', label: 'Time Off' },
        { value: 'zealEmployee', label: 'Zeal Employee Onboarding' },
    ];

    useEffect(() => {
        setName(formName);
        setFormType(formformType);
        setIsLocationRequired(formLocationRequired);
        teamId &&
            setSelectedTeamOptions([
                { value: teamId, label: teams.find((team) => teamId === (team.id || team._id))?.name },
            ]);
    }, [teamId, formName, formformType, formLocationRequired]);

    const onEdit = (form) => {
        dispatch({ type: 'ADD_FORM_DATA', payload: form });
    };

    const handleItem = (data) => {
        dispatch({ type: 'REORDER_FORM', payload: data });
    };

    const handleDelete = (formElementId) => {
        dispatch({ type: 'DELETE_FORM_ELEMENT', payload: formElementId });
    };

    const set_Evv_Default = () => {
        dispatch({ type: 'SET_EVV_DEFAULT' });
    };
    const set_Pto_Default = () => {
        dispatch({ type: 'SET_PTO_DEFAULT' });
    };
    const set_Zeal_Employee_Default = () => {
        dispatch({ type: 'SET_ZEAL_EMPLOYEE_DEFAULT' });
    };
    const handleDeleteForm = () => {
        dispatch({ type: 'REMOVE_fORM' });
        if (teamId) {
            history.push(`/`);
        } else {
            history.push('/master-form-library');
        }
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const tempItems = reorder(formElements, result.source.index, result.destination.index);

        handleItem(tempItems);
    };

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? 'lightgreen' : 'white',

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? 'lightblue' : 'white',
        padding: formElements.length ? grid : 0,
    });

    const handleFormSave = async () => {
        try {
            setLoading(true);

            const formData = {
                name,
                type: formType?.value || formType,
                specialityForm: {
                    ...(formType === 'speciality' ? { type: specialityFormType } : {}),
                },
                data: formElements.reduce((acc, current) => {
                    if (current.fieldName) {
                        return acc.concat(current);
                    } else {
                        let tempCurrent = { ...current };
                        delete tempCurrent.fieldName;
                        return acc.concat(tempCurrent);
                    }
                }, []),
                isLocationOptional: !isLocationRequired,
                visibleTo,
            };

            if (!name) {
                return setError({ name: 'Form Name is required', room: '' });
            }
            if (!selectedRoomOptions?.length && !channelId) {
                return setError({ room: 'Chat Room is required' });
            }
            if (formData?.type === 'speciality' && !specialityFormType) {
                return setError({ specialityFormType: 'Speciality Form Type is required' });
            }
            const isServiceOptionsEmpty = formData.data.some(
                (element) => element.key === 'ServiceRadio' && element.data.length === 0
            );

            if (isServiceOptionsEmpty) {
                return toast.error('Services Options must not be empty! Please add Service options.');
            }

            formElements.forEach((element) => {
                if (['pdf', 'photo'].includes(element?.type) && !element?.data) {
                    throw new Error(`Please upload file in ${element.type}`);
                }
            });

            // no teamId then its admin view
            if (!teamId) {
                if (!selectedRoomOptions.length) {
                    return setError({ name: '', room: 'Chat room is required' });
                }
                formData.chatroom = selectedRoomOptions.map((opt) => opt.value);
            } else {
                if (channelId) {
                    formData.chatroom = [channelId];
                } else if (selectedRoomOptions.length) {
                    formData.chatroom = selectedRoomOptions.map((opt) => opt.value);
                }
                formData.team = [teamId];
            }

            if (editformId) {
                await fetchWrapper.put(`forms/${editformId}`, formData);
                toast.success('Form Edited Successfully');
            } else {
                await fetchWrapper.post('forms', formData);
                toast.success('Form Added');
            }

            setError({});
            dispatch({ type: 'REMOVE_fORM' });
            if (channelId) {
                history.push(`/form/`);
            } else if (teamId) {
                history.push(`/form/`);
            } else {
                history.push('/master-form-library');
            }
        } catch (err) {
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleServiceFormChange = (selectedOption) => {
        if (formType === 'normal' || formType.value === 'normal') {
            if (
                selectedOption.value !== 'normal' &&
                selectedOption.value !== 'multipleSubmission' &&
                selectedOption.value !== 'speciality'
            ) {
                set_Evv_Default();
            }
        }
        if (formType !== 'speciality') {
            setSpecialityFormType({});
        }
        setFormType(selectedOption.value);
    };

    const handleLocationChange = () => {
        setIsLocationRequired(!isLocationRequired);
    };

    const handleCopy = (form) => {
        var elementOptions = {
            id: ID.uuid(),
            key: form.key,
            fieldName: form.fieldName,
            type: form.type,
        };
        if (form.instruction) {
            elementOptions['instruction'] = form.instruction;
        }
        if (form.data) {
            let copyFormData = form.data.map((data) => Object.assign({}, data));
            let uniquiedValuesFormData = copyFormData.map((data) => {
                return {
                    ...data,
                    value: ID.uuid(),
                };
            });
            elementOptions['data'] = uniquiedValuesFormData;
        }
        dispatch({
            type: 'ADD_FORM_DATA',
            payload: elementOptions,
        });
    };

    return (
        <div className="w-100 custom-card">
            <Form className=" mb-4 mt-2 text-dark-grey">
                <Form.Row>
                    <Form.Label>Form Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={name || ''}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={placeholderName}
                    />
                    <Form.Text className="text-danger">{error.name}</Form.Text>
                </Form.Row>
                <div
                    className="d-flex"
                    css={css`
                        gap: 12px;
                        padding: 12px 0;
                    `}
                >
                    <div className="d-flex flex-column w-100">
                        <Form.Label>Form Type</Form.Label>
                        <Select
                            classNamePrefix="select"
                            isSearchable
                            name="serviceform"
                            options={options}
                            defaultValue={options.filter((option) => option.value === formType)[0]}
                            onChange={handleServiceFormChange}
                        />
                    </div>
                    <div className="d-flex flex-column w-100">
                        <Form.Label>Visible To </Form.Label>
                        <div
                            className="h-auto overflow-hidden d-flex"
                            css={css`
                                border: 1px solid #ccc;
                                border-radius: 4px;
                                padding: 4px 6px;
                            `}
                        >
                            {['user', 'doctor', 'family', 'supervisor'].map((role) => (
                                <div key={role}>
                                    <input
                                        id={`chatInput_${role}`}
                                        type="checkbox"
                                        css={customCheckbox}
                                        onChange={handleVisibleToChange}
                                        value={role}
                                        checked={visibleTo?.includes(role)}
                                        disabled={
                                            role === 'supervisor' &&
                                            ['user', 'doctor', 'family'].some((basicRole) =>
                                                visibleTo.includes(basicRole)
                                            )
                                        }
                                    />
                                    <label
                                        htmlFor={`chatInput_${role}`}
                                        className="mr-4"
                                        css={css`
                                            text-transform: capitalize;
                                        `}
                                    >
                                        {{ doctor: 'Third Party' }[role] ?? role}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Speciality Form */}
                {formType === 'speciality' && (
                    <div
                        className="d-flex"
                        css={css`
                            gap: 12px;
                            padding: 12px 0;
                        `}
                    >
                        <div className="d-flex flex-column w-100">
                            <Form.Label>Speciality Form Type</Form.Label>
                            <Select
                                classNamePrefix="select"
                                isSearchable
                                name="specialityFormType"
                                options={specialityFormOptions}
                                defaultValue={
                                    specialityFormOptions.filter((option) => option.value === specialityFormType)[0]
                                }
                                onChange={(option) => {
                                    setSpecialityFormType(option.value);
                                    (
                                        ({
                                            timeOff: set_Pto_Default,
                                            zealEmployee: set_Zeal_Employee_Default,
                                        })[option?.value] ??
                                        (() => {
                                            console.log('Invalid Speciality Form Type', option?.value);
                                        })
                                    )();
                                }}
                            />
                            <Form.Text className="text-danger">{error.specialityFormType}</Form.Text>
                        </div>
                    </div>
                )}
                {teamId ? null : (
                    <Form.Row>
                        <div className="w-100">
                            <Form.Label>Teams</Form.Label>
                            <TeamSelectFilter
                                isMulti
                                disabled={!!teamId}
                                selectedTeamOption={selectedTeamOptions}
                                setSelectedTeamOption={setSelectedTeamOptions}
                            />
                        </div>
                    </Form.Row>
                )}
                <Form.Row>
                    <div className="w-100">
                        <Form.Label>Chatrooms</Form.Label>
                        <ChanelSelectFilter
                            isMulti
                            teamIds={selectedTeamOptions?.map((option) => option?.value)}
                            disabled={!!channelId}
                            assignedChannel={channelId}
                            selectedOption={selectedRoomOptions}
                            onRoomSelect={setSelectedRoomOptions}
                        />
                        <Form.Text className="text-danger">{error.room}</Form.Text>
                    </div>
                </Form.Row>
                <div
                    className="d-flex"
                    css={css`
                        gap: 12px;
                        padding: 12px 0 0;
                        justify-content: flex-end;
                    `}
                >
                    <input
                        id="is_location_required"
                        type="checkbox"
                        css={customCheckbox}
                        onChange={handleLocationChange}
                        value={isLocationRequired}
                        checked={isLocationRequired}
                    />
                    <label
                        htmlFor="is_location_required"
                        className="mr-4"
                        css={css`
                            text-transform: capitalize;
                        `}
                    >
                        Location Required
                    </label>
                </div>
            </Form>
            <hr />
            <Card
                className="border-primary"
                css={css`
                    min-height: 150px;
                    height: fit-content;
                `}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {formElements.length > 0 &&
                                    formElements.map((form, index) => (
                                        <Draggable key={form.id} draggableId={form.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div
                                                        key={form.id}
                                                        className={`form-element `}
                                                        css={css`
                                                            margin-bottom: 5px;
                                                            padding: 1rem 0.5rem 1rem 1rem;
                                                        `}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <FormElement
                                                                form={form}
                                                                isPreview
                                                                className="text-dark-grey h5"
                                                            />
                                                            <div className="form-element-control">
                                                                <Fragment>
                                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                                        <EditElement element={form} onEdit={onEdit} />

                                                                        {form.key === 'ServiceRadio' ? null : (
                                                                            <span
                                                                                css={css`
                                                                                    cursor: pointer;
                                                                                `}
                                                                                onClick={() => handleCopy(form)}
                                                                            >
                                                                                <AiFillCopy color="#50aeb0" />
                                                                            </span>
                                                                        )}

                                                                        {(
                                                                            typeof formType === 'object'
                                                                                ? formType.value === 'normal'
                                                                                : formType === 'normal'
                                                                        ) ? (
                                                                            <span
                                                                                css={css`
                                                                                    cursor: pointer;
                                                                                    height: 20px;
                                                                                    width: 20px;
                                                                                    margin-bottom: 20px;
                                                                                `}
                                                                                onClick={() => handleDelete(form.id)}
                                                                            >
                                                                                <MdDelete color="red" />
                                                                            </span>
                                                                        ) : form.required ? null : (
                                                                            <span
                                                                                css={css`
                                                                                    cursor: pointer;
                                                                                    height: 20px;
                                                                                    width: 20px;
                                                                                    margin-bottom: 20px;
                                                                                `}
                                                                                onClick={() => handleDelete(form.id)}
                                                                            >
                                                                                <MdDelete color="red" />
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </Fragment>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                {formElements.length === 0 && <div className="d-flex m-auto">Add Elements</div>}
            </Card>

            {formElements.length > 0 ? (
                <div className="w-100 d-flex justify-content-between mt-4">
                    <Button variant="secondary" onClick={() => handleDeleteForm()}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleFormSave} disabled={loading}>
                        Save
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => ({
    formElements: state.formData.formElements,
    teams: state.teams.teams,
    teamRooms: state.rooms.teamRooms,
    currUser: state.user.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicPreview);
