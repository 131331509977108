import * as ActionTypes from '../actions/ActionTypes';
import ID from '../../_helpers/uuid';

const initialState = {
    defaultSchema: {
        title: 'A registration form',
        description: 'A simple form example.',
        type: 'object',
        required: true,
        properties: {
            firstName: {
                type: 'string',
                title: 'First name',
                default: 'Chuck',
            },
            lastName: {
                type: 'string',
                title: 'Last name',
            },
        },
    },
    formElements: [],
    defaultEvvFormElements: [
        {
            fieldName: 'Header Text',
            id: ID.uuid(),
            instruction: 'Header Text',
            key: 'Header',
            type: 'textbox',
            required: true,
        },
        {
            data: [],
            fieldName: 'Services',
            id: ID.uuid(),
            instruction: 'Add Services from edit',
            key: 'ServiceRadio',
            type: 'radio',
            required: true,
        },
        {
            fieldName: 'Signature',
            id: ID.uuid(),
            key: 'Signature',
            type: 'signature',
            required: true,
        },
    ],
    defaultPtoFormElements: [
        {
            data: [],
            fieldName: 'Accrual Policy',
            id: ID.uuid(),
            key: 'AccrualPolicySelect',
            type: 'select',
            required: true,
        },
        {
            fieldName: 'Datetime Range',
            id: ID.uuid(),
            key: 'DateTimeRangePicker',
            type: 'datetimerange',
            required: true,
        },
        {
            fieldName: 'Signature',
            id: ID.uuid(),
            key: 'Signature',
            type: 'signature',
            required: true,
        },
    ],
    defaultZealEmployeeFormElements: [
        {
            data: [],
            id: ID.uuid(),
            key: 'Header',
            fieldName: 'Basic Information',
            type: 'textbox',
            instruction: '',
        },
        {
            required: true,
            data: [],
            key: 'Email',
            fieldName: 'Email',
            type: 'textbox',
            autofill: 'email',
            instruction: 'Valid email address is in the format yourname@example.com',
        },
        {
            required: true,
            data: [
                {
                    key: 'firstName',
                    name: 'First Name',
                    type: 'text',
                    autofill: 'firstName',
                },
                {
                    key: 'lastName',
                    name: 'Last Name',
                    type: 'text',
                    autofill: 'lastName',
                },
            ],
            id: ID.uuid(),
            key: 'FullName',
            fieldName: 'Full Name',
            type: 'textbox',
            instruction: '',
        },
        {
            required: true,
            data: [],
            id: ID.uuid(),
            key: 'DatePicker',
            fieldName: 'Date Of Birth',
            type: 'date',
            instruction: '',
        },
        {
            required: true,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Social Security Number',
            type: 'textbox',
            instruction: 'SSN must be a 9 digit number',
        },
        {
            required: false,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Address Line 1',
            type: 'textbox',
            instruction: 'Residential Street Address',
        },
        {
            required: false,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Address Line 2',
            type: 'textbox',
            instruction: 'Residential Street Address Line 2 (i.e. apt numbers, suite no., etc.)',
        },
        {
            required: true,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Residential City',
            type: 'textbox',
            instruction: '',
        },
        {
            required: true,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'State',
            type: 'textbox',
            instruction: 'State should be in standard two letter short form',
        },
        {
            required: true,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Zip',
            type: 'textbox',
            instruction: 'Zip code should be a number from 5 to 9 digits',
        },
        {
            required: true,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Phone Number',
            type: 'textbox',
            instruction: 'Phone number should be a number up to 10 digits',
        },
        {
            required: false,
            data: [],
            id: ID.uuid(),
            key: 'Header',
            fieldName: 'Employee Information',
            type: 'textbox',
            instruction: '',
        },
        {
            required: true,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Job Title',
            type: 'textbox',
            instruction: '',
        },
        {
            required: false,
            data: [
                {
                    text: 'Farmworker Employee',
                    value: 'is_943',
                },
                {
                    text: 'Household Employee',
                    value: 'is_scheduleH',
                },
            ],
            id: ID.uuid(),
            key: 'Checkbox',
            fieldName: 'Employee Type',
            type: 'checkbox',
            instruction:
                'Check "farmworker employee" for 943 employee and "household employee" for Schedule H Category Household Employee.',
        },
        {
            required: true,
            data: [
                {
                    text: 'Daily',
                    value: 'daily',
                },
                {
                    text: 'Weekly',
                    value: 'weekly',
                },
                {
                    text: 'Biweekly',
                    value: 'biweekly',
                },
                {
                    value: 'semimonthly',
                    text: 'Semimonthly',
                },
                {
                    value: 'monthly',
                    text: 'Monthly',
                },
            ],
            defaultValue: 'weekly',
            id: ID.uuid(),
            key: 'Select',
            fieldName: 'Default Pay Schedule',
            type: 'select',
            instruction:
                'Your manager will still be in charge of setting the reporting periods and check dates, but this field helps Zeal determine at what rate to tax.',
        },
        {
            required: true,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Default Wage',
            type: 'textbox',
            instruction: '',
        },
        {
            data: [],
            fieldName: 'Work Location',
            id: ID.uuid(),
            key: 'ZealEmployeeWorkLocationSelect',
            type: 'select',
            required: true,
        },
        {
            required: true,
            data: [],
            id: ID.uuid(),
            key: 'DatePicker',
            fieldName: 'Hire Date',
            type: 'date',
            instruction: 'This is the first day that this employee works in return for wages',
        },
        {
            required: false,
            data: [],
            id: ID.uuid(),
            key: 'DatePicker',
            fieldName: 'Work Week Start Date',
            type: 'date',
            instruction:
                'This is the date of beginning of the work week when the employee begins work. (Only applicable for FLSA Overtime Policy)',
        },
        {
            required: false,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Overtime Rate',
            type: 'textbox',
            instruction: '',
        },
        {
            required: false,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Doubletime Rate',
            type: 'textbox',
            instruction: '',
        },
        {
            required: false,
            data: [],
            id: ID.uuid(),
            key: 'Header',
            fieldName: 'Bank Account',
            type: 'textbox',
            instruction: '',
        },
        {
            required: false,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Institution Name',
            type: 'textbox',
            instruction: '',
        },
        {
            required: false,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Account Number',
            type: 'textbox',
            instruction: '',
        },
        {
            required: false,
            data: [],
            id: ID.uuid(),
            key: 'InputField',
            fieldName: 'Routing Number',
            type: 'textbox',
            instruction: '',
        },
        {
            required: false,
            data: [
                {
                    text: 'Checking',
                    value: 'checking',
                },
                {
                    text: 'Savings',
                    value: 'savings',
                },
            ],
            id: ID.uuid(),
            key: 'Select',
            fieldName: 'Account Type',
            type: 'select',
            instruction: '',
        },
    ],
};

export default function FormData(state = initialState, action) {
    let tempForm, index;
    switch (action.type) {
        case ActionTypes.REORDER_FORM:
            return { ...state, formElements: [...action.payload] };
        case ActionTypes.ADD_FORM_DATA:
            index = state.formElements.findIndex((form) => form.id === action.payload.id);
            if (index !== -1) {
                tempForm = [...state.formElements];
                tempForm[index] = action.payload;
                return { ...state, formElements: tempForm };
            }
            return { ...state, formElements: [...state.formElements, action.payload] };
        case ActionTypes.DELETE_FORM_ELEMENT:
            tempForm = [...state.formElements];
            index = tempForm.findIndex((form) => form.id === action.payload);
            tempForm.splice(index, 1);
            return { ...state, formElements: tempForm };
        case ActionTypes.REMOVE_fORM:
            return { ...state, formElements: [] };
        case ActionTypes.SET_EVV_DEFAULT:
            return { ...state, formElements: state.defaultEvvFormElements };
        case ActionTypes.SET_PTO_DEFAULT:
            return { ...state, formElements: state.defaultPtoFormElements };
        case ActionTypes.SET_ZEAL_EMPLOYEE_DEFAULT:
            return { ...state, formElements: state.defaultZealEmployeeFormElements };
        default:
            return state;
    }
}
