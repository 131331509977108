import React, { Fragment, useState } from 'react';
import { css } from 'twin.macro';
import { ActionStyles } from '../../../reusableStyles';

import { FaSuitcase, FaUser, FaRegCalendarAlt, FaRegClone } from 'react-icons/fa';
import { BsEye, BsPencil, BsTrash } from 'react-icons/bs';

import { ToolTip } from '../../../../../_elements/ToolTip';
import ZealEmployeeDeductionsModal from './ZealEmployeeDeductionsModal';
import { Button } from 'react-bootstrap';
import { PayHighlight } from './ZealCompanyPayroll';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { groupShiftsByVisit, shiftTypes } from './ZealEmployeeCheckForm';
import { getTotalHoursFromEmployeeCheckShifts } from '../PayrollUtils';
function getShiftAmount(shift, shiftName) {
    let amount = 0;
    try {
        switch (shift?.type || shiftName) {
            case 'hourly':
            case 'overtime':
            case 'doubletime':
            case 'pto':
            case 'vacation':
            case 'sick':
            case 'holiday':
            case 'correction':
                amount =
                    parseFloat(shift?.hours || shift?.[shiftName]?.hours || 0) *
                    parseFloat(shift?.wage || shift?.[shiftName]?.wage || 0);
                break;
            case 'reimbursement':
            case 'commodity':
            case 'commission':
            case 'nondiscretionary_bonus':
            case 'discretionary_bonus':
            case 'charged_tips':
            case 'flat':
                amount = parseFloat(shift?.amount || shift?.[shiftName]?.amount || 0);
                break;
            case 'piecework':
                amount =
                    parseFloat(shift?.amount_per_unit || shift?.[shiftName]?.amount_per_unit || 0) *
                    parseInt(shift?.units || shift?.[shiftName]?.units || 0);
                break;
            case 'gross_up':
                amount = parseFloat(shift?.desired_net || shift?.[shiftName]?.desired_net || 0);
                break;
            case 'shareholder_healthcare_reimbursement':
                amount = parseFloat(shift?.flat || shift?.[shiftName]?.flat || 0);
                break;
            default:
                amount = 0;
        }
        return amount?.toFixed(2);
    } catch (err) {
        console.log('err', err);
        return '--';
    }
}

function getShiftName(currentShift) {
    try {
        const shiftNames = Object.keys(shiftTypes);
        const currentShiftType = shiftNames.find((shift) => currentShift[shift]) || '';
        return currentShiftType;
    } catch (err) {
        console.log('err', err);
        return '-';
    }
}
export default function EmployeeCheckRow({
    employeeCheck,
    setShowEmployeeCheck,
    companyID,
    teamId,
    fromPayroll = false,
    preview = false,
    bulk = false,
    expand = false,
    loading = false,
}) {
    const [expanded, setExpanded] = useState(bulk ? expand : preview);
    const groupedTaxes = (employeeCheck?.taxes || []).reduce(
        (acc, item) => {
            if (item?.paidBy === 'EMPLOYEE_WITHHOLDING') {
                acc?.employee.push(item);
            } else {
                acc?.employer.push(item);
            }
            return acc;
        },
        { employee: [], employer: [] }
    );
    return (
        <div
            css={css`
                border: 2px solid #ddd;
                border-radius: 3px;
                padding: 6px 12px;
                box-shadow: ${expanded ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px' : ''};
            `}
        >
            <div
                css={css`
                    display: flex;
                    gap: 12px;
                    justify-content: space-between;
                `}
            >
                <div>
                    <div
                        css={css`
                            font-size: larger;
                        `}
                    >
                        {[employeeCheck?.first_name, employeeCheck?.middle_initial, employeeCheck?.last_name]
                            .filter((x) => x)
                            .join(' ')}
                    </div>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            align-items: start;
                        `}
                    >
                        <span
                            css={css`
                                font-size: small;
                            `}
                        >
                            {employeeCheck?.employeeCheckID}
                        </span>
                        <div
                            className="d-flex align-items-center"
                            css={css`
                                gap: 6px;
                            `}
                        >
                            <FaRegCalendarAlt />
                            <span>{employeeCheck.check_date}</span>
                            <span
                                className={`badge badge-sm ${employeeCheck?.status === 'pending' ? 'badge-warning' : employeeCheck?.status === 'processed' ? 'badge-success' : employeeCheck?.status === 'failed' ? 'badge-danger' : 'badge-info'}`}
                            >
                                {employeeCheck?.status}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    css={css`
                        display: flex;
                        gap: 24px;
                        flex-grow: 1;
                        margin: 0 24px;
                    `}
                >
                    {['gross_pay', 'net_pay', 'total_employee_taxes', 'total_employer_taxes'].map((key) => (
                        <React.Fragment key={key}>
                            {(employeeCheck || {})[key]?.toString() && (
                                <PayHighlight amount={employeeCheck[key]} label={key.replace('total_', '')} />
                            )}
                        </React.Fragment>
                    ))}
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        align-items: flex-end;
                        justify-content: flex-end;
                    `}
                >
                    {employeeCheck?.approval_required && !employeeCheck?.approved ? (
                        <Button onClick={() => setShowEmployeeCheck(employeeCheck, 'approve')}>Approve Check</Button>
                    ) : null}
                    <div className="d-flex flex-row align-items-end">
                        {preview || (
                            <ToolTip
                                text={'View Check'}
                                side="right"
                                cssStyles={css`
                                    right: 42px !important;
                                    top: 0 !important;
                                `}
                            >
                                <ActionStyles>
                                    <BsEye className="icon" onClick={() => setShowEmployeeCheck(employeeCheck)} />
                                </ActionStyles>
                            </ToolTip>
                        )}
                        {employeeCheck?.status === 'pending' ? (
                            <>
                                <ToolTip
                                    text={`Edit Check`}
                                    side="right"
                                    cssStyles={css`
                                        right: 42px !important;
                                        top: 0 !important;
                                    `}
                                >
                                    <ActionStyles>
                                        <BsPencil
                                            className="icon edit"
                                            onClick={() => setShowEmployeeCheck(employeeCheck, 'edit')}
                                        />
                                    </ActionStyles>
                                </ToolTip>

                                <ToolTip
                                    text={`Delete Check`}
                                    side="right"
                                    cssStyles={css`
                                        right: 42px !important;
                                        top: 0 !important;
                                    `}
                                >
                                    <ActionStyles>
                                        <BsTrash
                                            className="icon delete"
                                            onClick={() => setShowEmployeeCheck(employeeCheck, 'delete')}
                                        />
                                    </ActionStyles>
                                </ToolTip>
                                <ZealEmployeeDeductionsModal
                                    companyID={companyID}
                                    employeeCheck={employeeCheck}
                                    teamId={teamId}
                                />
                            </>
                        ) : (
                            <ToolTip
                                text={`${expanded ? 'Hide' : 'Show'} Pay Details`}
                                side="right"
                                cssStyles={css`
                                    right: 42px !important;
                                    top: 0 !important;
                                `}
                            >
                                <ActionStyles onClick={() => setExpanded((prev) => !prev)}>
                                    {expanded ? <IoIosArrowUp className="icon" /> : <IoIosArrowDown className="icon" />}
                                </ActionStyles>
                            </ToolTip>
                        )}
                        {employeeCheck?.status === 'failed' ? (
                            <ToolTip
                                text={`Clone`}
                                side="right"
                                cssStyles={css`
                                    right: 42px !important;
                                    top: 0 !important;
                                `}
                            >
                                <ActionStyles>
                                    <FaRegClone
                                        className="icon"
                                        onClick={() => setShowEmployeeCheck(employeeCheck, 'clone')}
                                    />
                                </ActionStyles>
                            </ToolTip>
                        ) : null}
                    </div>
                </div>
            </div>
            {expanded && (
                <div
                    css={css`
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        gap: 12px;
                        padding: 12px 0;
                    `}
                >
                    <div
                        css={css`
                            height: fit-content;
                            width: 33%;
                            display: flex;
                            flex-direction: column;
                        `}
                    >
                        <div
                            css={css`
                                border: 1px solid #ddd;
                                padding: 6px 12px;
                                height: fit-content;
                            `}
                        >
                            <div
                                css={css`
                                    font-weight: bold;
                                `}
                            >
                                Summary
                            </div>
                            <hr />
                            {Object.values(employeeCheck?.totals || {})?.length ? (
                                Object.entries(employeeCheck?.totals).map(([key, value], index) => (
                                    <p
                                        key={index}
                                        css={css`
                                            text-transform: capitalize;
                                        `}
                                    >
                                        {key.replace(/([a-z])_([a-z])/g, '$1 $2')}: ${parseFloat(value)?.toFixed(2)}
                                    </p>
                                ))
                            ) : (
                                <p>No Summary</p>
                            )}
                        </div>
                        <div
                            css={css`
                                border: 1px solid #ddd;
                                padding: 6px 12px;
                                height: fit-content;
                                margin-top: 8px;
                            `}
                        >
                            <div
                                css={css`
                                    font-weight: bold;
                                `}
                            >
                                Shifts Breakdown
                            </div>
                            <hr />
                            <EmployeeShiftBreakDown
                                checkShifts={employeeCheck?.shifts || []}
                                fromPayroll={fromPayroll}
                            />
                        </div>
                    </div>

                    <div
                        css={css`
                            border: 1px solid #ddd;
                            padding: 6px 12px;
                            width: 33%;
                        `}
                    >
                        <div
                            css={css`
                                font-weight: bold;
                            `}
                        >
                            Taxes
                        </div>
                        <hr />
                        <p
                            css={css`
                                font-weight: 600;
                            `}
                        >
                            Employer Taxes
                        </p>
                        {groupedTaxes?.employer?.length ? (
                            groupedTaxes?.employer?.map(({ name, paidBy, amount, jurisdiction }, index) => (
                                <p
                                    key={index}
                                    css={css`
                                        text-transform: capitalize;
                                        display: flex;
                                        gap: 8px;
                                        align-items: flex-start;
                                    `}
                                >
                                    <TaxResponsibleParty type={paidBy} />
                                    <span>
                                        {name}
                                        {jurisdiction ? `(${jurisdiction})` : ''}: ${parseFloat(amount)?.toFixed(2)}
                                    </span>
                                </p>
                            ))
                        ) : (
                            <p>No Taxes</p>
                        )}
                        <hr />
                        <p
                            css={css`
                                font-weight: 600;
                            `}
                        >
                            Employee Taxes
                        </p>
                        {groupedTaxes?.employee?.length ? (
                            groupedTaxes?.employee?.map(({ name, paidBy, amount, jurisdiction }, index) => (
                                <p
                                    key={index}
                                    css={css`
                                        text-transform: capitalize;
                                        display: flex;
                                        gap: 8px;
                                        align-items: flex-start;
                                    `}
                                >
                                    <TaxResponsibleParty type={paidBy} />
                                    <span>
                                        {name}
                                        {jurisdiction ? `(${jurisdiction})` : ''}: ${parseFloat(amount)?.toFixed(2)}
                                    </span>
                                </p>
                            ))
                        ) : (
                            <p>No Taxes</p>
                        )}
                    </div>
                    <div
                        css={css`
                            border: 1px solid #ddd;
                            padding: 6px 12px;
                            width: 33%;
                        `}
                    >
                        <div
                            css={css`
                                font-weight: bold;
                            `}
                        >
                            Deductions
                        </div>
                        <hr />
                        {employeeCheck?.deductions?.length ? (
                            employeeCheck.deductions.map(
                                (
                                    {
                                        deduction_template_name,
                                        deduction_type,
                                        employer_calculated_contribution,
                                        employee_calculated_contribution,
                                    },
                                    index
                                ) => (
                                    <p
                                        key={index}
                                        css={css`
                                            text-transform: capitalize;
                                            display: flex;
                                            gap: 6px;
                                        `}
                                    >
                                        {(deduction_template_name || deduction_type).replace(/_/g, ' ')}:
                                        <span
                                            css={css`
                                                display: flex;
                                                gap: 3px;
                                                margin-right: 6px;
                                            `}
                                        >
                                            <TaxResponsibleParty type="EMPLOYEE_WITHHOLDING" /> $
                                            {parseFloat(employee_calculated_contribution)?.toFixed(2)}
                                        </span>
                                        <span
                                            css={css`
                                                display: flex;
                                                gap: 3px;
                                            `}
                                        >
                                            <TaxResponsibleParty type="EMPLOYER_LIABILITY" /> $
                                            {parseFloat(employer_calculated_contribution)?.toFixed(2)}
                                        </span>
                                    </p>
                                )
                            )
                        ) : (
                            <p>No Deductions</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

function TaxResponsibleParty({ type }) {
    return (
        {
            EMPLOYER_LIABILITY: (
                <FaSuitcase
                    className="text-info mt-1"
                    css={css`
                        flex-shrink: 0;
                    `}
                />
            ),
            EMPLOYEE_WITHHOLDING: (
                <FaUser
                    className="text-info mt-1"
                    css={css`
                        flex-shrink: 0;
                    `}
                />
            ),
        }[type] || <FaUser />
    );
}

export const EmployeeShiftBreakDown = ({ checkShifts = [], fromPayroll }) => {
    const visitWithShifts = groupShiftsByVisit(checkShifts);
    const totalHours = getTotalHoursFromEmployeeCheckShifts(checkShifts);
    return (
        <div>
            {(
                Object.values(visitWithShifts).sort(
                    (a, b) => new Date(a.shifts[0].time) - new Date(b.shifts[0].time)
                ) || []
            ).map((visit, visitIndex) => {
                return (
                    <div className="mb-3" key={visitIndex}>
                        <span className="badge badge-info mr-2">Shift {visitIndex + 1}</span>
                        {(visit?.shifts || [])?.length ? (
                            visit?.shifts?.map((shift, index) => {
                                const shiftType = shift?.type || getShiftName(shift);
                                const isVisit = shiftType === 'hourly';
                                const hours = shift?.hourly?.hours || shift?.hours || 0;
                                return (
                                    <div
                                        key={index}
                                        css={css`
                                            text-transform: capitalize;
                                        `}
                                    >
                                        <span
                                            css={css`
                                                margin-right: 4px;
                                                width: 100px;
                                            `}
                                        >
                                            {shiftType} {isVisit && ' Visit'}:
                                        </span>
                                        $
                                        {fromPayroll
                                            ? getShiftAmount(shift, getShiftName(shift))
                                            : getShiftAmount(shift)}
                                        {isVisit && ` | ${hours} hrs`}
                                    </div>
                                );
                            })
                        ) : (
                            <p>No Shifts</p>
                        )}
                    </div>
                );
            })}
            <div
                css={css`
                    border-top: 1px solid #ccc;
                `}
            >
                {totalHours > 0 && <p>Total Visit Hours: {totalHours} hrs</p>}
            </div>
        </div>
    );
};
