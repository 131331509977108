//authentication types
export const SET_USER = 'SET_USER';
export const SET_NEW_USER = 'SET_NEW_USER';
export const SET_USER_ROOMS = 'SET_USER_ROOMS';
export const SET_CHATABLE_USER = 'SET_CHATABLE_USER';
export const CLEAR_CHATABLE_USER = 'CLEAR_CHATABLE_USER';
export const ARCHIVE_USER = 'ARCHIVE_USER';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const CLEAR_USER_LOADING = 'CLEAR_USER_LOADING';
export const SET_LOGGEDIN = 'SET_LOGGEDIN';
export const SET_LOGGEDOUT = 'SET_LOGGEDOUT';
export const SET_ALL_USER = 'SET_ALL_USER';
export const SET_POSITION = 'SET_POSITION';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_POS_ERROR = 'SET_POS_ERROR';
export const SET_LAST_SEEN_CHANNEL = 'SET_LAST_SEEN_CHANNEL';
export const SET_LAST_SEEN_SUB_CHANNEL = 'SET_LAST_SEEN_SUB_CHANNEL';

// user interface types
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const SET_SHOW_MESSAGE = 'SET_SHOW_MESSAGE';
export const CLEAR_SHOW_MESSAGE = 'CLEAR_SHOW_MESSAGE';
export const SET_NEW_USER_REDIRECT = 'SET_NEW_USER_REDIRECT';

//Chat rooms Types
export const SET_ROOMS = 'SET_ROOMS';
export const ADD_ROOMS = 'ADD_ROOMS';
export const NOT_SEEN_ROOM = 'NOT_SEEN_ROOM';
export const HAS_SEEN_ROOM = 'HAS_SEEN_ROOM';
export const SET_NEW_ROOM = 'SET_NEW_ROOM';
export const SET_CHANNEL_MEMBER = 'SET_CHANNEL_MEMBER';
export const SET_NEW_ROOM_REF = 'SET_NEW_ROOM_REF';
export const ADD_CLIENT_ROOMS = 'ADD_CLIENT_ROOMS';
export const SET_IS_ADDING_ROOM = 'SET_IS_ADDING_ROOM';

// export const UPDATE_CHANNEL_MEMBER = 'UPDATE_CHANNEL_MEMBER';
export const SET_NEW_CHANNEL_MEMBER = 'SET_NEW_CHANNEL_MEMBER';
export const REMOVE_CHANNEL_MEMBER = 'REMOVE_CHANNEL_MEMBER';
export const REMOVE_CHANNEL = 'REMOVE_CHANNEL';
export const REMOVE_USER_CHANNEL = 'REMOVE_USER_CHANNEL';
export const EDIT_CHANNEL = 'EDIT_CHANNEL';
export const UPDATE_CHANNEL_NOTIFICATION = 'UPDATE_CHANNEL_NOTIFICATION';

//Messages Types
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_SOCKET_MESSAGE = 'SET_SOCKET_MESSAGE';
export const SET_CONFERENCE_END = 'SET_CONFERENCE_END';
export const SET_CONFERENCE_LEAVE = 'SET_CONFERENCE_LEAVE';
export const SET_CONFERENCE_JOIN = 'SET_CONFERENCE_JOIN';

export const SET_SEARCH_MESSAGES = 'SET_SEARCH_MESSAGES';
export const CLEAR_SEARCH_MESSAGES = 'CLEAR_SEARCH_MESSAGES';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const SET_TEAM_USER = 'SET_TEAM_USER';
export const SET_PRIVATE_MESSAGES = 'SET_PRIVATE_MESSAGES';
export const CLEAR_PRIVATE_MESSAGES = 'CLEAR_PRIVATE_MESSAGES';
export const SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE';
export const STOP_LOADING_MESSAGE = 'STOP_LOADING_MESSAGE';
export const SET_UPLOADING = 'SET_UPLOADING';
export const CLEAR_UPLOADING = 'CLEAR_UPLOADING';
export const SET_LAST_SEEN_MESSAGE = 'SET_LAST_SEEN_MESSAGE';
export const REMOVE_SEND_MESSAGE = 'REMOVE_SEND_MESSAGE';
export const UPDATE_SEND_MESSAGE = 'UPDATE_SEND_MESSAGE';
export const IS_NEW_MESSAGE = 'IS_NEW_MESSAGE';
export const MESSAGE_EMOJI = 'MESSAGE_EMOJI';

//chat user Types
export const SET_CHAT_USERS = 'SET_CHAT_USERS';
export const SET_SOCKET_CHAT_USERS = 'SET_SOCKET_CHAT_USERS';
export const SET_LOADING_CHAT = 'SET_LOADING_CHAT';
export const STOP_LOADING_CHAT = 'STOP_LOADING_CHAT';

//organization Types
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const REMOVE_ORGANIZATION = 'REMOVE_ORGANIZATION';

//Teams Type
export const SET_TEAMS = 'SET_TEAMS';
export const SET_TEN_TEAMS = 'SET_TEN_TEAMS';
// export const SET_PUBLIC_TEAMS = 'SET_PUBLIC_TEAMS';
// export const REMOVE_PUBLIC_TEAMS = 'REMOVE_PUBLIC_TEAMS';
export const SET_NEW_TEAM = 'SET_NEW_TEAM';
export const SET_EDIT_TEAM = 'SET_EDIT_TEAM';
export const REMOVE_TEAM = 'REMOVE_TEAM';
export const REMOVE_USER_TEAM = 'REMOVE_USER_TEAM';
export const SET_TEAM_MEMBER = 'SET_TEAM_MEMBER';
export const SET_TEAM_LOADING = 'SET_TEAM_LOADING';
export const CLEAR_TEAM_LOADING = 'CLEAR_TEAM_LOADING';
export const SET_NEW_TEAM_MEMBER = 'SET_NEW_TEAM_MEMBER';
export const SET_TEAM_OWNERSHIP = 'SET_TEAM_OWNERSHIP';
export const SET_MANAGER = 'SET_MANAGER';

//activity types SET_NEW_ACTIVITY
export const SET_ALL_ACTIVITY = 'SET_ALL_ACTIVITY';
export const MARK_ALL_AS_READ_ACTIVITIES = 'MARK_ALL_AS_READ_ACTIVITIES';
export const READ_ACTIVITY_DECREMENT = 'READ_ACTIVITY_DECREMENT';
export const SET_NEW_ACTIVITY = 'SET_NEW_ACTIVITY';
export const CLEAR_ACTIVITIES = 'CLEAR_ACTIVITIES';
export const CLEAR_ACTIVITY = 'CLEAR_ACTIVITY';
export const SET_ACTIVITY_MESSAGE = 'SET_ACTIVITY_MESSAGE';
export const CLEAR_ACTIVITY_MESSAGE = 'CLEAR_ACTIVITY_MESSAGE';
export const MARK_AS_READ_ACTIVITY = 'MARK_AS_READ_ACTIVITY';

//admin
// chatmessages
export const SET_ALL_CHATROOM_MESSAGES = 'SET_ALL_CHATROOM_MESSAGES';

//form
export const ADD_FORM_DATA = 'ADD_FORM_DATA';
export const REORDER_FORM = 'REORDER_FORM';
export const DELETE_FORM_ELEMENT = 'DELETE_FORM_ELEMENT';
export const REMOVE_fORM = 'REMOVE_fORM';
export const SET_EVV_DEFAULT = 'SET_EVV_DEFAULT';
export const SET_PTO_DEFAULT = 'SET_PTO_DEFAULT';
export const SET_ZEAL_EMPLOYEE_DEFAULT = 'SET_ZEAL_EMPLOYEE_DEFAULT';

//task
export const SET_TASKLIST = 'SET_TASKLIST';
export const SET_NEW_TASK = 'SET_NEW_TASK';
export const SET_EDIT_TASK = 'SET_EDIT_TASK';
export const DELETE_THE_TASK = 'DELETE_THE_TASK';
export const START_TASK_LOADING = 'START_TASK_LOADING';
export const STOP_TASK_LOADING = 'STOP_TASK_LOADING';

//Chatroom Data
export const SET_CHATROOM_FORMS = 'SET_CHATROOM_FORMS';
export const CLEAR_CHATROOM_FORMS = 'CLEAR_CHATROOM_FORMS';
export const SET_CHATROOM_FILES = 'SET_CHATROOM_FILES';
export const SET_CHATROOM_MEDIAS = 'SET_CHATROOM_MEDIAS';

// EVV

export const SET_EVVSTATE = 'SET_EVVSTATE';
export const SET_EVVFORMS = 'SET_EVVFORMS';

export const SET_PROVIDERS = 'SET_PROVIDERS';
export const SET_ALL_PROVIDERS = 'SET_ALL_PROVIDERS';
export const SET_EVV_LOADING = 'SET_EVV_LOADING';
export const CLEAR_EVV_LOADING = 'CLEAR_EVV_LOADING';
export const SET_NEW_PROVIDER = 'SET_NEW_PROVIDER';
export const REMOVE_PROVIDER = 'REMOVE_PROVIDER';

export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_ALL_EMPLOYEES = 'SET_ALL_EMPLOYEES';
export const SET_NEW_EMPLOYEE = 'SET_NEW_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE';

export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_ALL_CLIENTS = 'SET_ALL_CLIENTS';
export const SET_NEW_CLIENT = 'SET_NEW_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';

export const SET_VISITS = 'SET_VISITS';
export const SET_VISITS_COST_INFO = 'SET_VISITS_COST_INFO';
export const SET_ALL_VISITS = 'SET_ALL_VISITS';
export const SET_NEW_VISIT = 'SET_NEW_VISIT';
export const UPDATE_VISIT = 'UPDATE_VISIT';
export const REMOVE_VISIT = 'REMOVE_VISIT';

export const SET_SERVICE = 'SET_SERVICE';
export const SET_ALL_SERVICES = 'SET_ALL_SERVICES';
export const SET_NEW_SERVICE = 'SET_NEW_SERVICE';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';

export const SET_LABELS = 'SET_LABELS';

// ONLINE STATUS

export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';
export const SET_OFFLINE_STATUS = 'SET_OFFLINE_STATUS';

// Conference
export const SET_CONFERENCE_CALL_INVITATION = 'SET_CONFERENCE_CALL_INVITATION';
export const CLEAR_CONFERENCE_CALL_INVITATION = 'CLEAR_CONFERENCE_CALL_INVITATION';
// export const SET_CONFERENCE_ROOM = 'SET_CONFERENCE_ROOM';
// export const SET_CONFERENCE_TEAM = 'SET_CONFERENCE_TEAM';
export const SET_CONFERENCE_DATA = 'SET_CONFERENCE_DATA';
export const START_CONFERENCE = 'START_CONFERENCE';

//sub channels
export const SET_SUB_CHANNELS = 'SET_SUB_CHANNELS';
export const SET_SUB_CHANNEL_MEMBER = 'SET_SUB_CHANNEL_MEMBER';
export const SET_NEW_SUB_CHANNEL_MEMBER = 'SET_NEW_SUB_CHANNEL_MEMBER';
export const REMOVE_SUB_CHANNEL_MEMBER = 'REMOVE_SUB_CHANNEL_MEMBER';
