import React, { useEffect, useState, useCallback } from 'react';
import { css } from 'twin.macro';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import { toast } from 'react-toastify';
import { Checkbox } from '../Payroll/ZealComponents/Reports/ReportCheckBox';
//costume hooks
import {
    fetchWrapper,
    timeConvert,
    toastWrapper,
    getTeamIdFromURL,
    convertDateToISO,
    getStandardDate,
} from '../../../../_helpers';

// components
import { AdminTable } from '../../reusableStyles';
import Loader from '../../containers/Loader';
import SearchByProvider from '../EvvSearchModal/SearchByProvider';
import SearchByUserEmployee from '../EvvSearchModal/SearchByUserEmployee';
import PayrollBillModal from './PayrollBillModal';
import { connect } from 'react-redux';

export const getShiftBadge = (shift) => {
    if (shift === 'visit') {
        return 'badge-info';
    } else if (shift === 'pto') {
        return 'badge-dark';
    } else if (shift === 'reimbursement') {
        return 'badge-warning';
    }
    return 'badge-secondary';
};

const PayAllocation = ({ teams }) => {
    const history = useHistory();
    const isTeamSettings = !!useLocation().pathname.match(/user-payroll/);
    const [userEmployee, setUserEmployee] = useState();
    const [provider, setProvider] = useState({});
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(() => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 6);
        return currentDate;
    });

    const [loadingVisits, setLoadingVisits] = useState(false);
    const [workLocations, setWorkLocations] = useState([]);
    const [currentView, setCurrentView] = useState(0);
    const [shiftFilterTypes, setShiftFilterTypes] = useState({
        visit: true,
        pto: false,
        reimbursement: false,
    });

    const handleUserEmployeeSelect = (selectedUser) => {
        setUserEmployee(selectedUser);
    };

    const handleProviderSelect = (selectedProvider) => {
        setProvider(selectedProvider);
    };

    const handleCheckboxChange = (fieldName, newValue) => {
        setShiftFilterTypes((prevFields) => {
            const currentReimbursement = prevFields.reimbursement;
            const currentVisit = prevFields.visit;

            if (fieldName === 'reimbursement' && newValue && currentVisit) {
                toast.error('Cannot select both Reimbursement and Visit at the same time.');
                return prevFields;
            }

            if (fieldName === 'visit' && newValue && currentReimbursement) {
                toast.error('Cannot select both Reimbursement and Visit at the same time.');
                return prevFields;
            }

            return {
                ...prevFields,
                [fieldName]: newValue,
            };
        });
    };

    const teamId = getTeamIdFromURL();
    useEffect(() => {
        if (!isTeamSettings || !teamId) {
            return null;
        }
        const team = teams.find((team) => team.id === teamId);
        fetchWrapper
            .get(`evv/provider?team=${teamId}`)
            .then(({ results: [response] }) =>
                setProvider({
                    value: response?._id,
                    label: response?.agencyName,
                    providerId: response?._id,
                    teamId: team?.id,
                    agencyName: response?.agencyName,
                    team: response?._id,
                    state: response?.state,
                    payroll: response?.payroll,
                })
            )
            .catch((error) => {
                if (error.name === 'AbortError') {
                    return;
                }
                toastWrapper.error("Couldn't fetch provider data");
            });
    }, [teams, isTeamSettings]);

    const [visits, setVisits] = useState([]);
    const [selectedVisits, setSelectedVisits] = useState([]);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allEmployeesData = visits?.map((visitItem) => ({
                visitId: visitItem?._id,
                ...visitItem,
            }));
            setSelectedVisits(allEmployeesData);
        } else {
            setSelectedVisits([]);
        }
    };
    const handleSelectOne = (e, visit) => {
        const isExistsInList = !!selectedVisits?.find((visitItem) => visitItem?.visitId === visit?._id);
        if (!isExistsInList && e.target.checked) {
            setSelectedVisits([
                ...selectedVisits,
                {
                    visitId: visit?._id,
                    ...visit,
                },
            ]);
            return;
        }
        if (isExistsInList && !e.target.checked) {
            const filteredEmployeed = selectedVisits?.filter((visitItem) => visitItem?.visitId !== visit?._id);
            setSelectedVisits(filteredEmployeed);
            return;
        }
    };

    const getPayAllocation = async ({ employee, provider, endDate, startDate }) => {
        try {
            setLoadingVisits(true);
            const _shiftTypes = (Object.keys(shiftFilterTypes).filter((key) => shiftFilterTypes[key]) || []).join(',');
            const response = await fetchWrapper.get(
                `evv/visit/payable?${employee ? `&employee=${employee}` : ''}${provider ? `&provider=${provider}` : ''}${endDate ? `&serviceEndAt=${endDate}` : ''}${startDate ? `&serviceCreatedAt=${startDate}` : ''}${teamId ? `&team=${teamId}` : ''}&shiftFilter=${_shiftTypes}`
            );
            const tempShifts = [];
            response?.results?.forEach((visit) => {
                if (visit?.label === 'reimbursement') {
                    const reimbursementVisit = {
                        ...visit,
                        _id: visit?._id + `__${visit?.label}`,
                        extraInfo: {
                            ...visit.extraInfo,
                            staffAmount:
                                ((visit?.extraInfo?.mileageData?.distance || 0) *
                                    visit?.provider?.payroll?.metadata?.mileageReimbursementRate) /
                                1609.34,
                        },
                    };
                    tempShifts.push(reimbursementVisit);
                } else {
                    tempShifts.push({
                        ...visit,
                        _id: visit?._id + `__${visit?.label}`,
                    });
                }
            });
            setVisits(tempShifts);
            if (response?.stack && response?.code > 299) {
                throw response;
            }
        } catch (e) {
            if (e.name === 'AbortError') {
                return;
            }
            console.log('e', e);
            toastWrapper.error(e?.message || 'Something went wrong');
        } finally {
            setLoadingVisits(false);
            setSelectedVisits([]);
        }
    };

    const fetchWorkLocations = async (_companyID) => {
        try {
            const response = await fetchWrapper.get(
                `/evv/payroll/zeal/provider/${_companyID}/work-locations${teamId ? `?team=${teamId}` : ''}`
            );
            setWorkLocations(response);
        } catch (error) {
            if (error?.name === 'AbortError') return;
            toast.error(error?.message || "Work locations couldn't be fetched");
        }
    };

    const handleSearch = useCallback(() => {
        let canFetch = true;
        if (!provider?.value || currentView !== 0) {
            canFetch = false;
        }
        if (canFetch) {
            const fetchOptions = {
                employee: userEmployee?.id,
                provider: provider?.value,
                startDate: convertDateToISO(startDate, { start: true }),
                endDate: convertDateToISO(endDate, { end: true }),
            };
            getPayAllocation(fetchOptions);
        }
    }, [provider?.value, userEmployee?.id, endDate, startDate, currentView, shiftFilterTypes]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSearch();
        }, 500);
        return () => clearTimeout(timer);
    }, [handleSearch, history]);

    const handleEndDateChange = (date) => {
        if (!date) {
            setEndDate(null);
            return;
        }
        setEndDate(date.toISOString());
    };
    const handleStartDateChange = (date) => {
        if (!date) {
            setStartDate(null);
            return;
        }
        setStartDate(date.toISOString());
    };

    useEffect(() => {
        if (provider?.value) {
            const _companyID = provider?.payroll?.metadata?.companyID;
            if (_companyID) {
                fetchWorkLocations(_companyID);
            } else {
                setWorkLocations([]);
            }
        }
    }, [provider?.value]);

    const Views = [
        <>
            <div
                className="d-flex flex-row align-items-center mb-4"
                css={css`
                    justify-content: space-between;
                `}
            >
                <div
                    css={css`
                        min-width: 260px;
                    `}
                >
                    <SearchByProvider
                        provider={provider}
                        handleSelect={handleProviderSelect}
                        isDisabled={isTeamSettings}
                        team={teamId}
                    />
                </div>
                <div
                    css={css`
                        min-width: 260px;
                    `}
                >
                    <SearchByUserEmployee
                        userEmployee={userEmployee}
                        handleSelect={handleUserEmployeeSelect}
                        provider={provider}
                        disabled={!provider?.value}
                        team={teamId}
                    />
                </div>
                <div>
                    <span>Start Date:</span>
                    <br />
                    <DateTimePicker
                        onChange={handleStartDateChange}
                        value={startDate ? new Date(startDate) : startDate}
                        format="MM-dd-y"
                        disableClock={true}
                        calendarType={'US'}
                        clearIcon={null}
                        css={css`
                            background: white;
                            height: 36px;
                        `}
                    />
                </div>
                <div>
                    <span>End Date:</span>
                    <br />
                    <DateTimePicker
                        onChange={handleEndDateChange}
                        value={endDate ? new Date(endDate) : endDate}
                        format="MM-dd-y"
                        disableClock={true}
                        calendarType={'US'}
                        clearIcon={null}
                        css={css`
                            background: white;
                            height: 36px;
                        `}
                    />
                </div>
            </div>
            <div
                className="d-flex"
                css={css`
                    font-size: 16px !important;
                    padding: 0 0 12px 0;
                    flex-direction: row;
                    justify-content: space-between;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <div
                        css={css`
                            margin-right: 10px;
                        `}
                    >
                        Shift Types:
                    </div>
                    <div
                        css={css`
                            display: flex;
                            gap: 6px;
                            align-items: flex-start;
                        `}
                    >
                        {Object.keys(shiftFilterTypes).map((field) => (
                            <Checkbox
                                key={field}
                                label={field.replace('_', ' ')}
                                value={shiftFilterTypes[field]}
                                onChange={(newValue) => handleCheckboxChange(field, newValue)}
                            />
                        ))}
                    </div>
                </div>
                <div
                    className="badge badge-info"
                    css={css`
                        font-size: 16px !important;
                    `}
                >
                    Total Staff Amount: $
                    {visits
                        ?.map(({ extraInfo = {} }) => extraInfo?.staffAmount || 0)
                        ?.reduce((a, b) => a + b, 0)
                        ?.toFixed(2)}
                </div>
            </div>
            <div
                className="custom-card d-flex flex-col"
                css={css`
                    // min-height:65vh;
                    max-height: 70vh;
                    overflow: auto;
                    position: relative;
                `}
            >
                <AdminTable className="w-100 text-dark-grey">
                    <thead style={{ position: 'sticky', top: '-16px' }}>
                        <tr>
                            <th className="text-center">
                                <input
                                    type="checkbox"
                                    style={{ height: '20px', width: '20px', cursor: 'pointer' }}
                                    onChange={handleSelectAll}
                                    checked={selectedVisits?.length === visits?.length}
                                />
                            </th>
                            <th>SN</th>
                            <th>Label</th>
                            <th>Employee</th>
                            <th>Client</th>
                            <th>Start Date</th>
                            <th>Total Time/Distance</th> {/* Actual Value is Staff Time , only title is Staff Time */}
                            <th>Staff Rate</th>
                            <th>Staff Amount</th>
                        </tr>
                    </thead>
                    <tbody className="text-light-grey">
                        {provider?.value ? (
                            loadingVisits ? (
                                <tr>
                                    <td colSpan={9}>
                                        <div
                                            className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                            css={css`
                                                margin-top: 100px;
                                                width: 100%;
                                            `}
                                        >
                                            <Loader size="sm" top={100} />
                                        </div>
                                    </td>
                                </tr>
                            ) : visits && visits?.length ? (
                                <>
                                    {visits?.map((visit, idx) => {
                                        const badgeClass = getShiftBadge(visit?.label);
                                        const totalTime =
                                            visit?.label === 'pto'
                                                ? timeConvert(visit?.extraInfo?.requestedBalance * 60 * 60)
                                                : visit?.label === 'reimbursement'
                                                  ? `${(visit?.extraInfo?.mileageData?.distance / 1609.34).toFixed(2)} miles`
                                                  : timeConvert(visit?.extraInfo?.staffMinutesConsumed * 60);
                                        const staffRate = visit?.extraInfo?.staffRate;
                                        const staffAmount = visit?.extraInfo?.staffAmount;

                                        return (
                                            <tr key={`${visit?._id || idx}-${visit?.label}`}>
                                                <td className="text-center">
                                                    <input
                                                        type="checkbox"
                                                        style={{ height: '20px', width: '20px', cursor: 'pointer' }}
                                                        onChange={(e) => {
                                                            handleSelectOne(e, visit);
                                                        }}
                                                        checked={
                                                            !!selectedVisits?.find(
                                                                (employee) => employee?.visitId === visit?._id
                                                            )
                                                        }
                                                    />
                                                </td>
                                                <td>{idx + 1}</td>
                                                <td>
                                                    <span className={`badge badge-pill ${badgeClass}`}>
                                                        {visit?.label}
                                                    </span>
                                                </td>
                                                <td>
                                                    {visit.employee?._id ? (
                                                        visit?.employee?.user?.name
                                                    ) : (
                                                        <span className="text-danger">Employee deleted</span>
                                                    )}
                                                </td>
                                                <td>{`${visit?.client?.firstName || '-'}  ${visit?.client?.lastName || ''}`}</td>
                                                <td>
                                                    {dayjs(
                                                        visit.ScheduleStartTime ||
                                                            visit?.specialityForm?.data?.startDate ||
                                                            null
                                                    ).format('MM-DD-YYYY, h:mm A')}
                                                </td>
                                                <td>{totalTime}</td>
                                                <td>{staffRate?.toFixed(2)}</td>
                                                <td>{staffAmount?.toFixed(2)}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td colSpan={6}></td>
                                        <td>Selected Staff Amount</td>
                                        <td>
                                            $
                                            {selectedVisits
                                                ?.map(({ extraInfo = {} }) => extraInfo?.staffAmount || 0)
                                                ?.reduce((a, b) => a + b, 0)
                                                ?.toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7}></td>
                                        <td>
                                            <Button
                                                onClick={() => setCurrentView(1)}
                                                css={css`
                                                    width: 128px;
                                                `}
                                                disabled={selectedVisits?.length < 1}
                                            >
                                                Preview and Create Checks
                                            </Button>
                                        </td>
                                    </tr>
                                </>
                            ) : (
                                <tr>
                                    <td colSpan={8}>
                                        <div
                                            className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                            css={css`
                                                margin-top: 100px;
                                                width: 100%;
                                            `}
                                        >
                                            No Visits/Shifts Found
                                        </div>
                                    </td>
                                </tr>
                            )
                        ) : (
                            <tr>
                                <td colSpan={8}>
                                    <div
                                        className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                        css={css`
                                            margin-top: 100px;
                                            width: 100%;
                                        `}
                                    >
                                        Please Select a Provider !
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </AdminTable>
            </div>
        </>,
        <PayrollBillModal
            gotoScreenOne={() => {
                setCurrentView(0);
            }}
            selectedVisits={selectedVisits}
            provider={provider}
            teamId={teamId}
            workLocations={workLocations}
            defaultReportingDataQuery={{
                paySchedule: 'weekly',
                startDate: getStandardDate(startDate),
                endDate: getStandardDate(endDate),
            }}
        />,
    ];
    return (
        <>
            <div
                css={css`
                    margin: 12px 0;
                    background: ${isTeamSettings ? '#eee' : '#fff'};
                    padding: 12px 6px;
                    border-radius: 3px;
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <div>
                    {['Select Employees', 'Preview and Create Checks'].map((name, index) => (
                        <span
                            key={index}
                            css={css`
                                color: ${index === currentView ? '#000;' : '#888'};
                            `}
                        >
                            {name}
                            {index < Views.length - 1 ? ' > ' : ''}
                        </span>
                    ))}
                </div>
                <div>
                    <span
                        className="badge badge-info"
                        css={css`
                            font-size: 16px !important;
                        `}
                    >
                        {provider?.payroll?.type}
                    </span>
                </div>
            </div>
            {Views[currentView]}
        </>
    );
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
});

export default connect(mapStateToProps)(PayAllocation);
