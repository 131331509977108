import React, { useEffect } from 'react';
import { css } from 'twin.macro';
import { useState } from 'react';
import { formatAMPM } from '../../../_helpers';
import { connect } from 'react-redux';
import { deleteTheTask, editTheTask, editTaskStatus, getTheTask } from '../../../_redux/actions/ActionTasks';
import DeleteTask from '../../Admin/Tasks/DeleteTask';
import PreviewEventModal from '../../Admin/Tasks/PreviewEventModal';
import EventModal from '../../Admin/Tasks/EventModal';
import { ToolTip } from '../../../_elements/ToolTip';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { checkPermission } from '../../../_helpers/roles/check-roles';
import { useHistory } from 'react-router-dom';
import ViewEventChannels from '../../Admin/Tasks/eventchannel/ViewEventChannels';
import OnDemandEventAddModal from './OnDemandEventAddModal';

// styles
import { TaskStyles } from './styles/TaskStyles';

// icons
import { UPrimaryButton } from '../../../_elements/Buttons';
import { HiDotsVertical, HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi';
import { BsArrowBarRight, BsArrowRepeat } from 'react-icons/bs';
import { EventModalForm } from '../../Admin/Tasks/EventModalForm';
import { CreateEventStyle } from '../../Admin/Tasks/Styles/EventModalStyles';

const Task = ({
    task,
    mmddyyyyFormatedDate,
    editTheTask,
    userRole,
    deleteTheTask,
    isAdminPanel,
    editTaskStatus,
    currUser,
    showEventId,
    setShowEventId,
    addOrRemoveEvents,
    clickedEvent,
    isTaskFetched,
    onFetchTask,
    getTheTask,
    selectedMonthYear,
    clickedEventPackage,
    addToCalendarView,
}) => {
    const {
        _id,
        title,
        description,
        assignedUser,
        date,
        latestDatePointer,
        assignedChannel,
        status: taskStatus,
        recurring,
        eventPackage,
        eventMode,
    } = task;

    const id = _id || task.id;
    const userId = currUser.id || currUser._id;
    const [recurringNumber, recurringUnit] = recurring
        ? ({ daily: '1-days', weekly: '1-weeks', monthly: '1-months' }[recurring.toLowerCase()] || recurring).split('-')
        : [0, null];

    const assignedChannelInfo = Array.isArray(task?.assignedChannelInfo)
        ? task?.assignedChannelInfo[0]
        : task?.assignedChannelInfo || {};

    const { _id: teamId, name: team } = assignedChannelInfo.teamData || {};
    const channel = assignedChannelInfo.title;

    const time = formatAMPM(new Date(latestDatePointer || date));
    const [showModal, setShowModal] = useState(null);
    const [editTask, setEditTask] = useState(false);

    const history = useHistory();

    const status = taskStatus.includes(currUser._id) ? 'done' : 'incomplete';
    const handleCompletedClick = (id, status) => {
        let newTask = {
            status: task.status.includes(userId)
                ? task.status.filter((uId) => uId !== userId)
                : [...task.status, userId],
        };

        editTaskStatus(id, newTask, status);
    };

    const handleEditClick = async (newTasks) => {
        if (!isTaskFetched && id) {
            await getTheTask(id);
            onFetchTask();
        }
        let success = editTheTask(id, newTasks, selectedMonthYear);
        return success;
    };

    const handleShowEvent = async (id) => {
        setShowModal(id);
        if (!isTaskFetched && id) {
            await getTheTask(id);
            onFetchTask();
        }
    };

    const handleClose = () => {
        setShowModal(null);
    };

    const handleEventChannel = (id) => {
        if (id) {
            history.push(`/event-channel/${id}`);
        }
    };

    useEffect(() => {
        if (addToCalendarView?._id) {
            handleShowEvent(addToCalendarView?._id);
        }
    }, [addToCalendarView]);

    return (
        <TaskStyles className="d-flex align-items-center mb-4 cursor-pointer">
            <div
                css={css`
                    width: 115px;
                    padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                `}
            >
                {eventMode !== 'onDemand' ? (
                    <div className="mb-2">
                        <span className={`text-uppercase ${status === 'incomplete' ? '' : 'text-muted'} small`}>
                            {time}
                        </span>
                    </div>
                ) : null}
                <div
                    css={css`
                        // width:116.63px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    <div className={`mb-0 ${status === 'incomplete' ? '' : 'text-muted'} small`}>
                        {mmddyyyyFormatedDate}
                    </div>
                </div>
            </div>
            <span className={`line ${status === 'incomplete' ? 'ongoing-task-indicator' : 'greenline'}`}></span>
            <div className="event-content">
                <div className="titleAndDescrip small">
                    <div
                        className="font-weight-bold text-capitalize opto-text"
                        css={css`
                            font-size: 17px;
                        `}
                        onClick={() => handleShowEvent(task._id)}
                    >
                        {(eventPackage?._id || eventPackage)?.length > 0 ? (
                            <span
                                css={css`
                                    font-size: 17px;
                                `}
                            >
                                {title}
                                {eventPackage ? (
                                    <span className="badge badge-warning mx-2 " style={{ textTransform: 'lowercase' }}>
                                        plan
                                    </span>
                                ) : null}
                            </span>
                        ) : (
                            <span> {title}</span>
                        )}

                        {recurringUnit && recurringNumber && (
                            <span
                                className={`ml-4 px-3 d-inline-flex badge text-capitalize recurring-${{ days: 'Daily', weeks: 'Weekly', months: 'Monthly' }[recurringUnit]}`}
                                style={{ alignItems: 'center' }}
                            >
                                <BsArrowRepeat fontSize="18" className="mr-2" />
                                {`${recurringNumber > 1 ? recurringNumber : ''} ${recurringUnit[0].toUpperCase()}`}
                            </span>
                        )}
                    </div>
                    {showModal === task._id ? <PreviewEventModal data={task} handleClose={handleClose} /> : null}
                    {team && (
                        <div className="text-muted">
                            <span className="font-weight-bold">Team:&nbsp;&nbsp;</span>
                            <span>{team}</span>
                        </div>
                    )}
                    {channel && (
                        <div className="text-muted">
                            <span className="font-weight-bold">Channel:&nbsp;&nbsp;</span>
                            <span>{channel}</span>
                        </div>
                    )}
                    <div className="grey font-italic ellipsis">{description}</div>
                </div>
                {assignedUser && (
                    <div>
                        <ViewEventChannels handleEventChannel={handleEventChannel} eventId={id} teamId={teamId} />
                    </div>
                )}

                <div className="event-act-buttons">
                    {checkPermission(userRole, 'modify:event') && (
                        <Dropdown className="dropper">
                            <Dropdown.Toggle id="dropdown-basic" className="dropdown">
                                <HiDotsVertical className="text-muted" size={20} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu">
                                <Dropdown.Item
                                    onClick={() => {
                                        setEditTask(true);
                                    }}
                                >
                                    <span>Edit</span>
                                </Dropdown.Item>
                                <DeleteTask title={title} deleteTheTask={deleteTheTask} id={id} />
                            </Dropdown.Menu>
                        </Dropdown>
                    )}

                    <div className="d-flex justify-content-center align-items-center pr-5">
                        {assignedUser?.includes(userId) ? (
                            <div
                                onClick={(event) => {
                                    handleCompletedClick(task._id, event.target.value);
                                }}
                            >
                                {status === 'incomplete' ? (
                                    <ToolTip
                                        text="Mark Complete?"
                                        cssStyles={
                                            'top:-68% !important; min-width: 111px !important; left: -88px !important'
                                        }
                                    >
                                        <UPrimaryButton className="bg-transparent border-0">
                                            <HiOutlineExclamationCircle
                                                size={30}
                                                className="text-secondary cursor-pointer"
                                            />
                                        </UPrimaryButton>
                                    </ToolTip>
                                ) : (
                                    <ToolTip
                                        text="Event has completed"
                                        cssStyles={
                                            'top:-68% !important; min-width: 111px !important; left: -88px !important'
                                        }
                                    >
                                        <UPrimaryButton className="bg-transparent border-0">
                                            <HiOutlineCheckCircle size={35} className="text-primary cursor-pointer" />
                                        </UPrimaryButton>
                                    </ToolTip>
                                )}
                            </div>
                        ) : null}
                        <ToolTip
                            text="Go to channel"
                            cssStyles={'top:-68% !important; min-width: 95px !important; left: -88px !important'}
                        >
                            {isAdminPanel ? (
                                <a
                                    href={`/teams/${assignedChannelInfo?._id}`}
                                    className="btn btn-link"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsArrowBarRight size={40} className="text-primary" />
                                </a>
                            ) : (
                                <Link to={`/teams/${assignedChannelInfo?._id}`} target="_blank" rel="noreferrer">
                                    <BsArrowBarRight size={40} className="text-primary" />
                                </Link>
                            )}
                        </ToolTip>
                    </div>
                </div>
            </div>
            {showEventId === _id ? (
                <OnDemandEventAddModal
                    setShowEventId={setShowEventId}
                    addOrRemoveEvents={addOrRemoveEvents}
                    clickedEvent={clickedEvent}
                    clickedEventPackage={clickedEventPackage}
                    // mode={mode}
                />
            ) : null}
            <CreateEventStyle
                size="lg"
                centered
                show={editTask}
                onHide={() => {
                    setEditTask(false);
                }}
            >
                <EventModalForm
                    data={task}
                    handleSubmitTask={handleEditClick}
                    handleClose={() => {
                        setEditTask(false);
                    }}
                    teamIds={[teamId]}
                />
            </CreateEventStyle>
        </TaskStyles>
    );
};

const mapStateToProps = (state) => ({
    tasks: state.tasks,
    currUser: state.user.user,
});

export default connect(mapStateToProps, { deleteTheTask, editTheTask, editTaskStatus, getTheTask })(Task);
